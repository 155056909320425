import React from 'react'
import { table, tableRow, tableHeader, tableCell } from "../styles/table.module.scss"

export default function TableWrapper(props) {

    return (
        <div className={`w-full text-xs ${table}`}>
            
            {props.children}

        </div>
    )
}

export const TableRow = props => {

    const { header, content, contentClassName } = props; 

    if (!header || !content) return null

    return (

        <div className={tableRow}>
            <div className={tableHeader}>
                {header}
            </div>
            <div className={`${contentClassName ? [contentClassName, 'font-mono'].join(" ") : tableCell}`}>
                {content}
            </div>
        </div>
    )
}